@media screen and (max-width:768px){
    h1{
       font-size:3em;
    }    
  }





